<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <Visualization />
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3"
              >Projetos Finalizados</span
            >
            <div class="text-900 font-medium text-xl">03</div>
          </div>
          <div
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem; background-color: #d7ffbe"
          >
            <Image src="/images/dashIcon1.svg" alt="Image" width="20" />
          </div>
        </div>
        <span class="text-green-500 font-medium">2 novos </span>
        <span class="text-500">no último mês</span>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3"
              >Projetos em Andamento</span
            >
            <div class="text-900 font-medium text-xl">03</div>
          </div>
          <div
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem; background-color: #d7ffbe"
          >
            <Image src="/images/dashIcon2.svg" alt="Image" width="12" />
          </div>
        </div>
        <span class="text-green-500 font-medium">2 novos </span>
        <span class="text-500">no último mês</span>
      </div>
    </div>
    <div class="col-12 xl:col-4">
      <div class="card">
        <span class="block text-500 font-medium mb-3">Novo Projeto</span>
        <div style="text-align: center; padding-top: 10px">
          <router-link to="/new-project" tag="button">
            <Button
              style="
                background: #90a956;
                font-family: 'Roboto';
                font-weight: 900;
                font-size: 20px;
                border: 1px solid #90a956;
                padding: 10px 20px;
              "
              label="Criar novo Projeto"
              href="/new-project"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Visualization from "../components/Map/Visualization.vue"
export default {
  data() {
    return {
      loading: true,
      center: { lat: -14.2400732, lng: -53.1805017 },
      species: null,
      speciesService: null,
      info: null,
      basicData: {
        labels: [],
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: "#d92546",
            data: [65, 59, 80, 81, 56, 55, 40],
          },
          {
            label: "My Second dataset",
            backgroundColor: "#FFA726",
            data: [28, 48, 40, 19, 86, 27, 90],
          },
        ],
      },
      basicOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
    };
  },
  computed: {
    mapData() {
      if (!this.info) return null;
      return this.info.mapa.features[0].geometry.coordinates[0][0].map((c) => ({
        lat: c[1],
        lng: c[0],
      }));
    },
    lineData() {
      if (!this.info) return null;
      return {
        labels: Array.from(
          { length: this.info.graficos1.length },
          (_, i) => i + 1
        ),
        datasets: [
          {
            label: "Informações",
            data: this.info.graficos1,
            fill: false,
            backgroundColor: "#d92546",
            borderColor: "#d92546",
            tension: 0.4,
          },
        ],
      };
    },
    lineData1() {
      if (!this.info) return null;
      return {
        labels: Object.keys(this.info.graficos2),
        datasets: [
          {
            label: "Informações",
            data: Object.values(this.info.graficos2),
            backgroundColor: "#d92546",
            borderColor: "#d92546",
          },
        ],
      };
    },
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {},
  components: {
    Visualization: Visualization
  }
};
</script>
