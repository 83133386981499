<script>
/* eslint-disable no-undef */
import { ref, onMounted } from "vue";
import { Loader } from "@googlemaps/js-api-loader";

const GOOGLE_MAPS_API_KEY = "AIzaSyAX2fNszPe6jtseVK1bk6GS1vjDFkLGJBo";

export default {
  name: "App",

  setup() {
    const loader = new Loader({
      libraries: ["drawing", "geocoder", "places", "marker"],
      apiKey: GOOGLE_MAPS_API_KEY,
    });

    const mapDiv = ref(null);
    let map = ref(null);

    onMounted(async () => {
      await loader.load();
      map.value = new google.maps.Map(mapDiv.value, {
        center: { lat: -13.197, lng: -51.644 },
        zoom: 4,
        labels: true,
        streetViewControl: false,
      });
    });

    return { mapDiv };
  },
};
</script>

<template>
  <div id="map-container">
    <div ref="mapDiv" style="width: 100%; height: 60vh" />
  </div>
</template>

<style>
#map-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
